import React from "react";
import PropTypes from "prop-types";

class SystemsSelect extends React.Component {
  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
    this.inputRef = React.createRef();
    this.state = {
      searchTerm: "",
      filteredSystems: [],
      systemsByRegion: {},
      regionKeys: [],
    };
  }

  updateFilteredSystems = (term = "", fromProps = true) => {
    const lowerTerm = term.toLowerCase();
    this.setState((state, props) => {
      const filteredSystems = (
        fromProps ? props.systems : state.filteredSystems
      )
        .filter((sys) => {
          try {
            return (
              sys.id.toLowerCase().includes(lowerTerm) ||
              (sys.name && sys.name.toLowerCase().includes(lowerTerm))
            );
          } catch (err) {
            console.warn(`Error with '${sys.id}': ${err}`);
            return false;
          }
        })
        .sort(({ id: id1 }, { id: id2 }) => {
          const firstStarts = id1.startsWith(lowerTerm);
          const secondStarts = id2.startsWith(lowerTerm);
          if (firstStarts && !secondStarts) {
            return -1;
          } else if (!firstStarts && secondStarts) {
            return 1;
          } else {
            return 0;
          }
        });

      const systemsByRegion = filteredSystems.reduce((ac, sys) => {
        if (sys.region) {
          if (!(sys.region in ac)) {
            ac[sys.region] = [];
          }
          ac[sys.region].push(sys);
        }
        return ac;
      }, {});

      const regionKeys = Object.keys(systemsByRegion).sort((reg1, reg2) => {
        const TEST_REGION = "il.test";
        if (reg1 === reg2) {
          return 0;
        } else if (reg1 === TEST_REGION) {
          return -1;
        } else if (reg2 === TEST_REGION) {
          return 1;
        } else if (reg1 > reg2) {
          return 1;
        } else {
          return -1;
        }
      });

      return { filteredSystems, systemsByRegion, regionKeys };
    });
  };

  componentDidMount() {
    if (Array.isArray(this.props.systems)) {
      this.updateFilteredSystems();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const currSystems = this.props.systems;
    const prevSystems = prevProps.systems;
    if (
      currSystems !== prevSystems &&
      Array.isArray(currSystems) &&
      ((Array.isArray(prevSystems) &&
        currSystems.length !== prevSystems.length) ||
        prevSystems === null)
    ) {
      this.updateFilteredSystems();
    } else if (
      this.state.filteredSystems !== prevState.filteredSystems &&
      this.state.filteredSystems.length &&
      this.state.searchTerm !== ""
    ) {
      const firstSystem =
        this.state.systemsByRegion[this.state.regionKeys[0]][0];
      this.props.onSystemSelect(firstSystem.id);
    }
  }

  handleChangeSearchTerm = (ev) => {
    ev.preventDefault();
    const searchTerm = ev.target.value;
    const searchFromProps =
      this.state.searchTerm.length >= searchTerm.length ||
      !this.state.searchTerm.startsWith(searchTerm);
    this.setState({ searchTerm }, () =>
      this.updateFilteredSystems(searchTerm, searchFromProps)
    );
  };

  handleSystemSelect = (ev) => {
    ev.preventDefault();
    this.props.onSystemSelect(ev.target.value);
  };

  handleInputFocus = (ev) => {
    ev.preventDefault();
    ev.target.select();
  };

  render() {
    const { selectedSystem, disabled } = this.props;
    const { searchTerm, systemsByRegion, regionKeys } = this.state;

    return (
      <>
        <input
          ref={this.inputRef}
          value={searchTerm}
          disabled={disabled}
          onChange={this.handleChangeSearchTerm}
          onFocus={this.handleInputFocus}
          onKeyDown={(ev) => {
            if (["ArrowUp", "ArrowDown"].includes(ev.key)) {
              this.selectRef.current.focus();
            }
          }}
        />
        <select
          ref={this.selectRef}
          onChange={this.handleSystemSelect}
          value={selectedSystem}
          disabled={disabled}
          onKeyDown={(ev) => {
            if (["Backspace"].includes(ev.key)) {
              this.inputRef.current.focus();
            }
          }}
        >
          <option key="" value="">
            -
          </option>
          {regionKeys.map((region) => {
            return (
              <optgroup label={region} key={region}>
                {systemsByRegion[region].map((sys) => (
                  <option key={sys.id} value={sys.id}>
                    {sys.id}
                  </option>
                ))}
              </optgroup>
            );
          })}
        </select>
      </>
    );
  }

  static propTypes = {
    systems: PropTypes.array,
    selectedSystem: PropTypes.string,
    onSystemSelect: PropTypes.func,
  };
}

export default SystemsSelect;
